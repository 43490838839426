<template>
  <div />
</template>

<script>
  export default {
    name: 'Authenticated',

    created () {
      // if it's pdf skip checks
      if (this.$route.query.pdf === 'true') {
        return
      }

      // if is not auth instead redirect to login
      if (this.$session.get('isAuthenticated') !== true) {
        this.redirect()
      }
    },

    methods: {
      redirect () {
        this.$router.push('/')
      },
    }
  }
</script>