<template>
  <div />
</template>

<script>
export default {
  name: 'Logout',
  created () {
    this.$session.destroy()
    this.$router.push('/')
    window.location.reload()
  },
}
</script>
