<template>
  <v-container
    grid-list-md
    text-xs-center
    class="tabellaProdotto"
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        class="text-xs-left"
      >
        <h3>{{ title() }}</h3>
        <p v-html="description()" />
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
      elevation-1
      class="pa-3"
    >
      <v-flex
        xs3
        class="pr-2"
      >
        <img
          :src="getImage(1)"
          alt
          width="100%"
        >
        <img
          :src="getImage(2)"
          alt
          width="100%"
        >
        <img
          :src="getImage(3)"
          alt
          width="100%"
        >
      </v-flex>
      <!--
      -->
      <v-flex xs9>
        <v-layout>
          <v-flex
            xs4
            class="text-xs-right"
          >
            <h4>Cod.</h4>
          </v-flex>
          <v-flex
            xs8
            class="text-xs-left ml-2 mr-2"
          >
            <h4 style="font-weight: normal">
              {{ item.sku }}
            </h4>
          </v-flex>
        </v-layout>
        <v-layout style="background: #f5f5f5">
          <v-flex
            xs4
            class="text-xs-right"
          >
            <h4>{{ this.$store.state.translation[language].product.handling }}:</h4>
          </v-flex>
          <v-flex
            xs8
            class="text-xs-left ml-2 mr-2"
          >
            <h4 style="font-weight: normal">
              {{ handling() }}
            </h4>
          </v-flex>
        </v-layout>
        <!-- TMC -->
        <v-layout>
          <v-flex
            xs4
            class="text-xs-right"
          >
            <h4>{{ tmcTitle() }}:</h4>
          </v-flex>
          <v-flex
            xs8
            class="text-xs-left ml-2 mr-2"
          >
            <h4 style="font-weight: normal">
              {{ tmc() }}
            </h4>
          </v-flex>
        </v-layout>
        <v-layout style="background: #f5f5f5">
          <v-flex
            xs4
            class="text-xs-right"
          >
            <h4>{{ this.$store.state.translation[language].product.size }}:</h4>
          </v-flex>
          <v-flex
            xs8
            class="text-xs-left ml-2 mr-2"
          >
            <h4 style="font-weight: normal">
              {{ width() }}
            </h4>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex
            xs4
            class="text-xs-right"
          >
            <h4>{{ this.$store.state.translation[language].product.packaging }}:</h4>
          </v-flex>
          <v-flex
            xs8
            class="text-xs-left ml-2 mr-2"
          >
            <h4 style="font-weight: normal">
              {{ packaging() }}
            </h4>
          </v-flex>
        </v-layout>
        <v-layout style="background: #f5f5f5">
          <v-flex
            xs4
            class="text-xs-right"
          >
            <h4>{{ palletType() }}:</h4>
          </v-flex>
          <v-flex
            xs8
            class="text-xs-left ml-2 mr-2"
          >
            <h4 style="font-weight: normal">
              {{ pallet() }}
            </h4>
          </v-flex>
        </v-layout>
        <v-layout v-if="hasCustomAmericanPallet()">
          <v-flex
            xs4
            class="text-xs-right"
          >
            <h4>{{ this.$store.state.translation[language].product.packagingAmerican }}:</h4>
          </v-flex>
          <v-flex
            xs8
            class="text-xs-left ml-2 mr-2"
          >
            <h4 style="font-weight: normal">
              {{ packagingAmerican() }}
            </h4>
          </v-flex>
        </v-layout>
        <v-layout v-if="hasAmericanPallet()">
          <v-flex
            xs4
            class="text-xs-right"
          >
            <h4>{{ palletTypeAmerican() }}:</h4>
          </v-flex>
          <v-flex
            xs8
            class="text-xs-left ml-2 mr-2"
          >
            <h4 style="font-weight: normal">
              {{ americanPallet() }}
            </h4>
          </v-flex>
        </v-layout>
        <v-layout :style="colorBackgroundChange('')">
          <v-flex
            xs4
            class="text-xs-right"
          >
            <h4>{{ this.$store.state.translation[language].product.weight }}:</h4>
          </v-flex>
          <v-flex
            xs8
            class="text-xs-left ml-2 mr-2"
          >
            <h4 style="font-weight: normal">
              {{ weight() }}
            </h4>
          </v-flex>
        </v-layout>
        <v-layout :style="colorBackgroundChange('background: #F5F5F5;')">
          <v-flex
            xs4
            class="text-xs-right"
          >
            <h4>{{ this.$store.state.translation[language].product.pdf }}:</h4>
          </v-flex>
          <v-flex
            v-if="pdfExists()"
            xs8
            class="text-xs-left ml-2 mr-2"
          >
            <a :href="pdf()">
              <h4 style="font-weight: normal">{{ this.$store.state.translation[language].product.download }}</h4>
            </a>
          </v-flex>
          <v-flex
            v-else
            xs8
            class="text-xs-left ml-2 mr-2"
          >
            <h4 style="font-weight: normal">
              n/a
            </h4>
          </v-flex>
        </v-layout>
        <v-layout :style="colorBackgroundChange('')">
          <v-flex
            xs4
            class="text-xs-right"
          >
            <h4>{{ this.$store.state.translation[language].product.price.original }}:</h4>
          </v-flex>
          <v-flex
            xs8
            class="text-xs-left ml-2 mr-2"
          >
            <h4 style="font-weight: normal">
              {{ priceRegular() | currency }} ({{ priceRegularPiece() | currency }}
              {{ this.$store.state.translation[language].product.alPezzo }})
            </h4>
          </v-flex>
        </v-layout>
        <!-- Price Discount -->
        <v-layout
          v-if="parseFloat(item.discounted) > 0"
          style="background: #000000; color: #ffffff"
        >
          <v-flex
            xs4
            class="text-xs-right"
          >
            <h4>{{ this.$store.state.translation[language].product.price.discounted }}:</h4>
          </v-flex>
          <v-flex
            xs8
            class="text-xs-left ml-2 mr-2"
          >
            <h4 style="font-weight: normal">
              <strong>{{ priceDiscountedFixed() | currency }}</strong>
              {{ this.$store.state.translation[language].prezzoCartone }} ·
              <strong>{{ item.discounted | currency }}</strong>
              {{ this.$store.state.translation[language].product.alPezzo }}
            </h4>
          </v-flex>
        </v-layout>
        <!-- Price off -->
        <v-layout
          v-if="parseFloat(item.off) > 0"
          style="background: #000000; color: #ffffff"
        >
          <v-flex
            xs4
            class="text-xs-right"
          >
            <h4>{{ this.$store.state.translation[language].product.price.discounted }}:</h4>
          </v-flex>
          <v-flex
            xs8
            class="text-xs-left ml-2 mr-2"
          >
            <h4 style="font-weight: normal">
              -{{ item.off }}% (
              <strong>{{ priceDiscounted() | currency }}</strong>
              {{ this.$store.state.translation[language].prezzoCartone }} ·
              <strong>{{ priceDiscountedPerItem() | currency }}</strong>
              {{ this.$store.state.translation[language].product.alPezzo }})
            </h4>
          </v-flex>
        </v-layout>
        <!-- Price Note -->
        <v-layout
          v-if="item.note.length > 0"
          style="background: #000000; color: #ffffff"
        >
          <v-flex
            xs4
            class="text-xs-right"
          >
            <h4>{{ this.$store.state.translation[language].product.price.discounted }}:</h4>
          </v-flex>
          <v-flex
            xs8
            class="text-xs-left ml-2 mr-2"
          >
            <h4
              style="font-weight: normal"
              v-html="brizer(item.note)"
            />
          </v-flex>
        </v-layout>
        <!-- Price per box -->
        <!-- <v-layout :style="colorBackgroundChange('background: #F5F5F5;')">
          <v-flex
            xs4
            class="text-xs-right"
          >
            <h4>{{ this.$store.state.translation[language].prezzoCartone }}:</h4>
          </v-flex>
          <v-flex
            xs8
            class="text-xs-left ml-2 mr-2"
          >
            <h4 style="font-weight: normal">
              {{ pricePerBox() | currency }}
            </h4>
          </v-flex>
        </v-layout> -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true,
    },
    language: { type: String, default: 'Italiano', required: true },
    priceList: { type: String, default: 'Italiano', required: true },
  },
  methods: {
    title: function () {
      if (this.language === 'Italiano') {
        return this.item.title
      } else if (this.language === 'Inglese') {
        const data = _.find(this.item.attributes, { name: 'titolo_en' })
        return _.get(data, 'value', '')
      } else if (this.language === 'Tedesco') {
        const data = _.find(this.item.attributes, { name: 'titolo_de' })
        return _.get(data, 'value', '')
      } else if (this.language === 'Spagnolo') {
        const data = _.find(this.item.attributes, { name: 'titolo_es' })
        return _.get(data, 'value', '')
      } else {
        const data = _.find(this.item.attributes, { name: 'titolo_fr' })
        return _.get(data, 'value', '')
      }
    },
    description: function () {
      if (this.language === 'Italiano') {
        return `<h4 style='font-weight:normal'>${this.item.descriptionApp}</h4>`
      } else if (this.language === 'Inglese') {
        const data = _.find(this.item.attributes, { name: 'grossisti_descrizione_en' })
        return `<h4 style='font-weight:normal'>${_.get(data, 'value', '')}</h4>`
      } else if (this.language === 'Tedesco') {
        const data = _.find(this.item.attributes, { name: 'grossisti_descrizione_de' })
        return `<h4 style='font-weight:normal'>${_.get(data, 'value', '')}</h4>`
      } else if (this.language === 'Spagnolo') {
        const data = _.find(this.item.attributes, { name: 'grossisti_descrizione_es' })
        return `<h4 style='font-weight:normal'>${_.get(data, 'value', '')}</h4>`
      } else {
        const data = _.find(this.item.attributes, { name: 'grossisti_descrizione_fr' })
        return `<h4 style='font-weight:normal'>${_.get(data, 'value', '')}</h4>`
      }
    },
    getImage: function (index) {
      if (this.item.thumbnails !== undefined) {
        return this.item.thumbnails[this.item.thumbnails.length - index]
      } else {
        return this.item.images[this.item.images.length - index]
      }
    },
    priceRegular: function () {
      let price = parseFloat(this.item.priceRegular)
      if (this.priceList === 'Straniero') {
        const data = _.find(this.item.attributes, { name: 'listino_estero' })
        const value = _.get(data, 'value')
        if (value !== undefined) {
          price = parseFloat(value)
        }
      }
      if (this.priceList === 'Frozen') {
        const dataF = _.find(this.item.attributes, { name: 'listino_frozen' })
        const valueF = _.get(dataF, 'value')
        if (valueF !== undefined) {
          price = parseFloat(valueF)
        }
      }
      return parseFloat(price)
    },
    priceRegularPiece: function () {
      let pieces = parseInt(this.boxPieces())

      if (pieces <= 0 || pieces === null || pieces === undefined) {
        pieces = parseInt(this.packetPieces())
      }
      return this.roundPrice(this.priceRegular() / pieces)
    },
    pricePerItem: function () {
      let price = parseFloat(this.item.priceRegular)
      if (this.priceList === 'Straniero') {
        const data = _.find(this.item.attributes, { name: 'listino_estero' })
        const value = _.get(data, 'value')
        if (value !== undefined) {
          price = parseFloat(value)
        }
      }
      if (this.priceList === 'Frozen') {
        const dataF = _.find(this.item.attributes, { name: 'listino_frozen' })
        const valueF = _.get(dataF, 'value')
        if (valueF !== undefined) {
          price = parseFloat(valueF)
        }
      }
      const pieces = parseInt(this.boxPieces())
      if (pieces <= 0 || pieces === null || pieces === undefined) {
        return this.roundPrice(price)
      }
      const total = price / pieces
      return this.roundPrice(total)
    },
    pricePerBox: function () {
      let pieces = parseInt(this.boxPieces())

      if (pieces <= 0 || pieces === null || pieces === undefined) {
        pieces = parseInt(this.packetPieces())
      }

      let price = this.pricePerItem()
      let total = price * pieces
      console.log('total pre', total)

      if (parseFloat(this.item.off) > 0) {
        total = total - (total * parseFloat(this.item.off)) / 100
      }
      return this.roundPrice(total)
    },
    roundPrice: function (num) {
      console.log('num', num)
      return Math.round((num + Number.EPSILON) * 100) / 100
    },
    priceDiscountedFixed: function () {
      let price = this.item.discounted * parseInt(this.boxPieces())
      return this.roundPrice(price)
    },
    priceDiscounted: function () {
      let price = this.priceRegular() - (this.priceRegular() * parseFloat(this.item.off)) / 100
      return this.roundPrice(price)
    },
    priceDiscountedPerItem: function () {
      let pricePerItem = this.priceDiscounted()
      let pieces = parseInt(this.boxPieces())

      if (pieces <= 0 || pieces === null || pieces === undefined) {
        pieces = parseInt(this.packetPieces())
      }
      return this.roundPrice(pricePerItem / pieces)
    },
    packaging: function () {
      // Ogni scatola contiene $Pezzi a cartone/$Pezzi per busta buste con $Pezzi per busta basi ciascuna. $Pezzi a cartone basi per scatola.
      const total = parseInt(this.boxPieces()) / parseInt(this.packetPieces())
      return this.$store.state.translation[this.language].packaging
        .replace('$pezzidiv', total)
        .replace('$pezzibusta', this.packetPieces())
        .replace('$pezzicartone', this.addCommas(this.boxPieces()))
    },
    packagingAmerican: function () {
      const dataP = _.find(this.item.attributes, { name: 'pezzi_ct_american' })
      const value = _.get(dataP, 'value', '')
      const total = parseInt(value) / parseInt(this.packetPiecesAmerican())
      return this.$store.state.translation[this.language].packaging
        .replace('$pezzidiv', total)
        .replace('$pezzibusta', this.addCommas(this.packetPiecesAmerican()))
        .replace('$pezzicartone', value)
    },
    weight: function () {
      const data = _.find(this.item.attributes, { name: 'grossisti_peso' })
      return this.addCommas(_.get(data, 'value', ''))
    },
    boxPieces: function () {
      const data = _.find(this.item.attributes, { name: 'Pezzi a cartone' })
      return this.addCommas(_.get(data, 'value', ''))
    },
    packetPieces: function () {
      const data = _.find(this.item.attributes, { name: 'Pezzi per busta' })
      return _.get(data, 'value', '')
    },
    packetPiecesAmerican: function () {
      const data = _.find(this.item.attributes, { name: 'pezzi_busta_american' })
      return _.get(data, 'value', this.packetPieces())
    },
    palletType: function () {
      let pallet_type = 'EPAL'
      const data_pallet = _.find(this.item.attributes, { name: 'tipo_pallet' })
      const value_pallet = _.get(data_pallet, 'value', pallet_type)

      return this.$store.state.translation[this.language].product.pallet.replace('$type', value_pallet)
    },
    pallet: function () {
      const data = _.find(this.item.attributes, { name: 'grossisti_pallet' })
      const value = _.get(data, 'value', '')

      const total = parseInt(value) * parseInt(this.boxPieces())
      return this.$store.state.translation[this.language].pallet
        .replace('$grossisti_pallet', this.addCommas(value))
        .replace('$totale', this.addCommas(total))
    },
    palletTypeAmerican: function () {
      return this.$store.state.translation[this.language].product.americanPallet
    },
    hasAmericanPallet: function () {
      const data = _.find(this.item.attributes, { name: 'american_pallet' })
      const value = _.get(data, 'value', '')
      return value !== ''
    },
    hasCustomAmericanPallet: function () {
      const dataP = _.find(this.item.attributes, { name: 'pezzi_ct_american' })
      const value = _.get(dataP, 'value', '')
      return value !== ''
    },
    colorBackgroundChange: function (color) {
      if (this.hasAmericanPallet()) {
        if (color === 'background: #F5F5F5;') {
          return ''
        } else {
          return 'background: #F5F5F5;'
        }
      } else {
        return color
      }
    },
    americanPallet: function () {
      const data = _.find(this.item.attributes, { name: 'american_pallet' })
      const value = _.get(data, 'value', '')

      const dataP = _.find(this.item.attributes, { name: 'pezzi_ct_american' })
      const valuePiceces = _.get(dataP, 'value', this.boxPieces())

      const total = parseInt(value) * parseInt(valuePiceces)
      return this.$store.state.translation[this.language].pallet
        .replace('$grossisti_pallet', this.addCommas(value))
        .replace('$totale', this.addCommas(total))
    },
    tmcTitle: function () {
      const handling = _.find(this.item.attributes, { name: 'Conservazione' })
      const thetype = _.get(handling, 'value', '')
      const isGelo = thetype === 'gelo' || thetype === 'gelo_in_atm'
      return isGelo
        ? this.$store.state.translation[this.language].shelflife
        : this.$store.state.translation[this.language].tmcTitle
    },
    tmc: function () {
      const handling = _.find(this.item.attributes, { name: 'Conservazione' })
      const thetype = _.get(handling, 'value', '')
      const isGelo = thetype === 'gelo' || thetype === 'gelo_in_atm'
      const minus = isGelo ? 0 : 15
      const length = isGelo
        ? this.$store.state.translation[this.language].monthsfromprod
        : this.$store.state.translation[this.language].days

      const data = _.find(this.item.attributes, { name: 'grossisti_durata' })
      const value = parseFloat(parseInt(_.get(data, 'value', '')) - minus)
      return this.$store.state.translation[this.language].product.tmc
        .replace('$result', value)
        .replace('$length', length)
        .replace('.', ',')
    },
    width: function () {
      const data = _.find(this.item.attributes, { name: 'grossisti_diametro' })
      return _.get(data, 'value', '')
    },
    handling: function () {
      const data = _.find(this.item.attributes, { name: 'Conservazione' })
      const key = _.get(data, 'value', '')
      return this.$store.state.translation[this.language].handling[key].replace('$grossisti_durata', this.expiration())
    },
    expiration: function () {
      const data = _.find(this.item.attributes, { name: 'grossisti_durata' })
      return _.get(data, 'value', '')
    },
    hasPdf: function () {
      const eanCartone = _.get(this, 'item.taxonomy.ean_cartone', '') !== ''
      return eanCartone
    },
    pdfExists: function () {
      return this.pdf() !== ''
    },
    pdf: function () {
      var url = ''

      if (this.hasPdf()) {
        var base_url = 'https://docs.quellidellapizza.eu/api/techsheet'
        if (this.language === 'Italiano') {
          base_url = `${base_url}/it`
        } else if (this.language === 'Inglese') {
          base_url = `${base_url}/en`
        } else if (this.language === 'Tedesco') {
          base_url = `${base_url}/de`
        } else if (this.language === 'Spagnolo') {
          base_url = `${base_url}/es`
        } else {
          base_url = `${base_url}/fr`
        }
        url = `${base_url}/${this.item.sku}`
      } else {
        if (this.language === 'Italiano') {
          const data = _.find(this.item.attributes, { name: 'scheda_manuale_it' })
          url = _.get(data, 'value', '')
        } else if (this.language === 'Inglese') {
          const data = _.find(this.item.attributes, { name: 'scheda_manuale_en' })
          url = _.get(data, 'value', '')
        } else if (this.language === 'Tedesco') {
          const data = _.find(this.item.attributes, { name: 'scheda_manuale_de' })
          url = _.get(data, 'value', '')
        } else if (this.language === 'Spagnolo') {
          const data = _.find(this.item.attributes, { name: 'scheda_manuale_es' })
          url = _.get(data, 'value', '')
        } else {
          const data = _.find(this.item.attributes, { name: 'scheda_manuale_fr' })
          url = _.get(data, 'value', '')
        }
      }
      return url
    },
    addCommas: function (num) {
      return ('' + num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, function ($1) {
        return $1 + '.'
      })
    },
    brizer: function (text) {
      return text.replace(/(?:\r\n|\r|\n)/g, '<br>')
    },
  },
}
</script>
