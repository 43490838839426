<template>
  <v-app>
    <v-navigation-drawer
      v-if="print === false"
      v-model="drawer"
      :clipped="clipped"
      persistent
      enable-resize-watcher
      fixed
      app
    >
      <v-list>
        <v-list-tile v-if="this.$session.get('user')">
          <v-list-tile-action>
            <v-avatar v-if="this.$session.get('user').avatar === ''">
              <img :src="this.$session.get('user').avatar">
            </v-avatar>
            <v-avatar
              v-if="this.$session.get('user').avatar !== ''"
              color="primary"
            >
              <span class="white--text headline">{{ getInitials() }}</span>
            </v-avatar>
          </v-list-tile-action>
          <v-list-tile-content v-if="this.$session.get('user')">
            <v-list-tile-title>{{ this.$session.get('user').name }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile
          v-for="(item, i) in items"
          :key="i"
          :to="item.url"
          value="true"
        >
          <v-list-tile-action>
            <v-icon v-html="item.icon" />
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title v-text="item.title" />
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar
      v-if="print === false"
      :clipped-left="clipped"
      app
    >
      <v-toolbar-side-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title v-text="title" />
      <v-spacer />
    </v-toolbar>
    <v-content>
      <router-view />
    </v-content>
    <v-footer
      v-if="print === false"
      :fixed="fixed"
      app
    >
      <span style="margin-left:20px">&copy; DIAL srl</span>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      print: false,
      clipped: true,
      drawer: true,
      fixed: true,
      items: [
        {
          icon: 'list',
          title: 'Tutti i Preventivi',
          url: '/list'
        },
        {
          icon: 'add',
          title: 'Nuovo Preventivo',
          url: '/new'
        }
      ],
      title: 'Quelli della Pizza'
    }
  },
  created () {
    this.print = this.$route.query.pdf === 'true'
    if (this.$session.get('isAuthenticated') === true) {
      this.items.push({
        icon: 'close',
        title: 'Logout',
        url: '/logout'
      })
    }
  },
  methods: {
    getInitials () {
      const name = this.$session.get('user').name
      const initials = name.match(/\b\w/g) || []
      return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
    }
  }
}
</script>

<style scoped>
#app {
  background-color: #ffffff;
}
</style>
