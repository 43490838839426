<template>
  <v-container fluid>
    <v-slide-y-transition mode="out-in">
      <v-layout 
        column 
        align-center
      >
        <img 
          src="@/assets/logo.png" 
          alt="Quelli della Pizza" 
          class="mb-5"
        >
        <v-form 
          ref="form" 
          v-model="valid" 
          lazy-validation
        >
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          />
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            label="Password"
            type="password"
            required
          />

          <v-btn
            :disabled="!valid"
            @click="submit"
          >
            login
          </v-btn>
          <v-btn @click="clear">
            clear
          </v-btn>
        </v-form>
      </v-layout>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'Login',
    data: () => ({
      valid: true,
      password: '',
      passwordRules: [
        v => !!v || 'Password is required',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
    }),

    methods: {
      submit () {
        if (this.$refs.form.validate()) {

          axios
              .post(`${this.$store.state.baseUrl}/api/auth/login`, { username: this.email , password: this.password })
              .then((resp) => {
                if (resp.status === 200) {
                  this.$session.start()
                  this.$session.set('isAuthenticated', true)
                  this.$session.set('user', resp.data)

                  this.$router.push('/list')
                }
              })
              .catch((err) => {
                // eslint-disable-next-line no-console
                alert(err.message)
                //console.log(err)
              })

        }
      },
      clear () {
        this.$refs.form.reset()
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
