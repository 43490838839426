import Vue from 'vue'
import Router from 'vue-router'
import Session from 'vue-session'
import Home from './views/Home.vue'
import Render from './views/Render.vue'
import Quotation from './views/Quotation.vue'
import List from './views/List.vue'
import Logout from './views/Logout.vue'

Vue.use(Router)
Vue.use(Session, {
  persist: true,
})

export default new Router({
  routes: [{
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/list',
      name: 'list',
      component: List
    },
    {
      path: '/new',
      name: 'quotation',
      component: Quotation
    },
    {
      path: '/edit/:id',
      name: 'edit',
      component: Quotation
    },
    {
      path: '/view/:id',
      name: 'render',
      component: Render
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout
    }
  ]
})
