<template>
  <v-card>
    <Authenticated />

    <v-card-title>
      Preventivi
      <v-spacer />
      <v-text-field
        v-model="search"
        color="blue"
        append-icon="search"
        label="Filtra"
        single-line
        hide-details
      />
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="quotation"
      :search="search"
      :pagination.sync="pagination"
      :loading="spinner"
      :total-items="total"
      :rows-per-page-items="rowsPerPageItems"
    >
      <template
        slot="items"
        slot-scope="props"
      >
        <tr>
          <td class="text-xs-left">
            {{ props.item.company }}
          </td>
          <td class="text-xs-left">
            {{ props.item.name }}
          </td>
          <td
            v-if="props.item.draft"
            class="text-xs-left"
          >
            <v-btn
              outline
              color="grey"
              small
            >
              BOZZA
            </v-btn>
          </td>
          <td
            v-else
            class="text-xs-left"
          >
            <v-btn
              dark
              color="green"
              small
            >
              COMPLETO
            </v-btn>
          </td>
          <td class="text-xs-left">
            {{ props.item.owner }}
          </td>
          <td class="text-xs-left">
            {{ props.item.language }}
          </td>
          <td class="text-xs-left">
            {{ props.item.updated | moment('DD/MM/YYYY') }}
          </td>
          <td class="text-xs-right">
            <v-tooltip bottom>
              <v-icon
                slot="activator"
                x-large
                class="mr-2"
                @click="openItem(props.item)"
              >
                visibility
              </v-icon>
              <span>Visualizza</span>
            </v-tooltip>

            <v-tooltip
              v-if="isOwner(props.item)"
              bottom
            >
              <v-icon
                slot="activator"
                x-large
                class="mr-2"
                @click="editItem(props.item)"
              >
                edit
              </v-icon>
              <span>Modifica</span>
            </v-tooltip>

            <v-tooltip
              v-if="isOwner(props.item)"
              bottom
            >
              <v-icon
                slot="activator"
                x-large
                class="mr-2"
                @click="duplicateItem(props.item)"
              >
                file_copy
              </v-icon>
              <span>Duplica</span>
            </v-tooltip>

            <v-tooltip
              v-if="isOwner(props.item)"
              bottom
            >
              <v-icon
                slot="activator"
                x-large
                class="mr-2"
                @click="deleteItem(props.item)"
              >
                delete
              </v-icon>
              <span>Cancella</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
      <v-alert
        slot="no-results"
        :value="true"
        color="error"
        icon="warning"
      >
        La ricerca per
        <em>"{{ search }}"</em> non ha portato alcun risultato.
      </v-alert>
    </v-data-table>
  </v-card>
</template>

<script>
import Authenticated from '../components/Authenticated'
import axios from 'axios'

export default {
  components: {
    Authenticated,
  },
  data () {
    return {
      search: '',
      spinner: false,
      total: 0,
      pagination: {},
      rowsPerPageItems: [50, 100, 200, 500],
      headers: [
        {
          text: 'Ragione Sociale',
          align: 'left',
          sortable: true,
          value: 'company',
        },
        {
          text: 'Referente',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Stato',
          align: 'left',
          sortable: false,
          value: 'draft',
        },
        {
          text: 'Creato da',
          align: 'left',
          sortable: true,
          value: 'owner',
        },
        {
          text: 'Lingua',
          align: 'left',
          sortable: true,
          value: 'language',
        },
        {
          text: 'Data Preventivo',
          align: 'left',
          sortable: true,
          value: 'date',
        },
        {
          text: 'Azioni',
          align: 'right',
          sortable: false,
        },
      ],
      apiheaders: {
        username: this.$session.get('user').name,
        token: this.$session.get('user').token,
      },
      quotation: [],
    }
  },
  watch: {
    pagination: {
      handler () {
        this.fetch()
      },
    },
    search: {
      handler () {
        this.fetch()
      },
    },
  },
  mounted () {
    this.fetch()
  },
  methods: {
    isOwner (quotatation) {
      return quotatation.owner === this.$session.get('user').name
    },
    editItem (item) {
      this.$router.push(`/edit/${item.id}`)
    },

    deleteItem (item) {
      confirm(`Sei sicuro di voler rimuovere il preventivo ${item.id} del ${item.date}?`) && this.deleteRemote(item.id)
    },

    deleteRemote (id) {
      axios
        .delete(`${this.$store.state.baseUrl}/api/quotation/${id}`, {
          headers: this.apiheaders,
        })
        .then((resp) => {
          if (resp.status === 200) {
            this.fetch()
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          alert(err)
        })
    },

    duplicateItem (item) {
      axios
        .get(`${this.$store.state.baseUrl}/api/quotation/${item.id}/duplicate`, { headers: this.apiheaders })
        .then((resp) => {
          if (resp.status === 200) {
            this.fetch()
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          alert(err)
        })
    },

    openItem (item) {
      this.$router.push(`/view/${item.id}`)
    },
    async fetch () {
      this.spinner = true
      const page = this.pagination.page || 1
      const rowsPerPage = this.pagination.rowsPerPage || 25
      const timeStamp = Math.floor(Date.now() / 1000)

      try {
        let url = `${this.$store.state.baseUrl}/api/quotation/paginated?limit=${rowsPerPage}&page=${page}&seed=${timeStamp}`

        if (this.search.length > 3) {
          url = `${url}&search=${encodeURI(this.search)}`
        }

        const resp = await axios.get(url, {
          headers: this.apiheaders,
        })

        this.quotation = resp.data.docs
        this.total = resp.data.totalDocs

        this.spinner = false
      } catch (error) {
        //
      }
    },
  },
}
</script>
