<template>
  <v-card tile>
    <v-toolbar
      card
      dark
      color="primary"
    >
      <v-btn
        icon
        dark
        @click.native="close"
      >
        <v-icon>close</v-icon>
      </v-btn>
      <v-text-field
        v-model="search"
        color="red"
        append-icon="search"
        label="Filtra"
        single-line
        hide-details
      />
    </v-toolbar>

    <v-progress-circular
      v-if="spinner"
      indeterminate
      color="primary"
    />

    <v-data-table
      v-if="spinner === false"
      :headers="headers"
      :items="products"
      :search="search"
    >
      <template
        slot="items"
        slot-scope="props"
      >
        <tr @click="pick(props.item)">
          <td class="text-xs-left">
            {{ props.item.sku }}
          </td>
          <td class="text-xs-left">
            <img
              v-if="props.item.thumbnails != undefined"
              :src="props.item.thumbnails[props.item.thumbnails.length-1]"
              height="80"
            >
          </td>
          <td class="text-xs-left">
            {{ props.item.title }}
          </td>
          <td class="text-xs-left">
            {{ handling(props.item.attributes) }}
          </td>
          <td class="text-xs-left">
            {{ pricePerItem(props.item) | currency }}
          </td>
        </tr>
      </template>
      <v-alert
        slot="no-results"
        :value="true"
        color="error"
        icon="warning"
      >
        La ricerca per
        <em>"{{ search }}"</em> non ha portato alcun risultato.
      </v-alert>
    </v-data-table>
  </v-card>
</template>

<script>
  import axios from 'axios'
  import _ from 'lodash'

  export default  {
    props: {
    priceList: { type: String, default: 'Italiano', required: true }
  },
    data () {
      return {
        search: '',
        products: [],
        spinner: false,
        headers: [
          {
            text: 'Codice',
            sortable: true,
            value: 'sku'
          },
          {
            text: 'Foto',
            sortable: false,
          },
          {
            text: 'Nome',
            sortable: true,
            value: 'title'
          },
          {
            text: 'Conservazione',
            sortable: false,
            value: 'handling'
          },
          {
            text: 'Prezzo Listino',
            sortable: true,
            value: 'price'
          }
        ],
      }
    },

    watch: {
      '$route': 'fetch'
    },
    created () {
      this.fetch()
    },
    methods: {
      close () {
        this.$emit('closePicker', false)
      },
      pick (item) {
        this.$emit('selectedItem', item)
      },
      fetch () {
        this.spinner = true
        axios
            .get(`${this.$store.state.baseUrl}/api/product`)
            .then((resp) => {
              if (resp.status === 200) {

                let prod = []
                resp.data.products.forEach((item) => {
                  // add extra fields
                  item.handling = this.handling(item.attributes)
                  item.off = 0
                  item.note = ''
                  item.discounted = 0
                  prod.push(item)
                })

                this.products = prod
              }
              this.spinner = false
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log(err)
            })
      },
      pricePerItem: function (item) {
        let price = item.priceRegular
        if (this.priceList === "Straniero"){
          const data = _.find(item.attributes, { name: 'listino_estero' })
          const value = _.get(data, 'value') 
          if (value !== undefined){
            price = value
          }
        }
        if (this.priceList === "Frozen") {
          const dataF = _.find(item.attributes, { name: 'listino_frozen' })
          const valueF = _.get(dataF, 'value') 
          if (valueF !== undefined){
            price = valueF
          }
        }
        return parseFloat(price)
      },
      handling: function (attributes) {
        const data = _.find(attributes, { name: 'Conservazione' })
        return _.get(data, 'value', '')
      }
    },
  }
</script>

<style scoped>
</style>

<!--
{
            name: 'BASE PIZZA PRIME CON LIEVITO MADRE (30CM)',
            code: '123456',
            images: {
              empty: 'https://quellidellapizza.it/wp-content/uploads/2016/10/base-pizza-prime_01.jpg',
              full:'https://quellidellapizza.it/wp-content/uploads/2016/10/base-pizza-prime_02.jpg'
            },
            packaging: 'Packaging',
            handling: 'Handling',
            length: '45 giorni',
            size: '30 cm',
            pallet: 'Pallet Info',
            description: 'Croccante e leggera\nBase Pizza Prime con Lievito Madre diametro 30cm.\nLa pizza, una volta farcita e cotta, si distingue per la sua caratteristica croccantezza e leggerezza.\nL’impasto è preparato con un’elevata quantità di acqua e una lunga lievitazione di 24 ore con lievito madre, tale da rendere il prodotto leggero, croccante e altamente digeribile.\nInoltre viene utilizzata acqua di mare microfiltrata e purificata, che garantisce un apporto aggiuntivo di sali minerali.',
            weight: 'Frigo (+4°)',
            pdf: 'https://quellidellapizza.it/wp-content/uploads/2017/06/prime-cod-221.pdf',
            price: {
              original: 10.00,
              discounted: 0.00,
              off: 0
            }
          },
          {
            name: 'BASE PIZZA (30CM)',
            code: '46232423',
            images: {
              empty: 'https://quellidellapizza.it/wp-content/uploads/2016/10/base-pizza-prime_01.jpg',
              full:'https://quellidellapizza.it/wp-content/uploads/2016/10/base-pizza-prime_02.jpg'
            },
            packaging: 'Packaging',
            handling: 'Handling',
            length: '45 giorni',
            size: '30 cm',
            pallet: 'Pallet Info',
            description: 'Croccante e leggera\nBase Pizza Prime con Lievito Madre diametro 30cm.\nLa pizza, una volta farcita e cotta, si distingue per la sua caratteristica croccantezza e leggerezza.\nL’impasto è preparato con un’elevata quantità di acqua e una lunga lievitazione di 24 ore con lievito madre, tale da rendere il prodotto leggero, croccante e altamente digeribile.\nInoltre viene utilizzata acqua di mare microfiltrata e purificata, che garantisce un apporto aggiuntivo di sali minerali.',
            weight: 'Frigo (+4°)',
            pdf: 'https://quellidellapizza.it/wp-content/uploads/2017/06/prime-cod-221.pdf',
            price: {
              original: 10.00,
              discounted: 0.00,
              off: 0
            }
          }
-->
