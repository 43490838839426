<template>
  <v-card flat>
    <authenticated />
    <v-container
      v-if="print === false"
      fluid
    >
      <v-layout
        row
        wrap
        align-center
      >
        <v-flex
          xs12f
          class="text-xs-center"
        >
          <img
            v-if="quotation.language != undefined"
            width="200"
            :src="require(`@/assets/logo-${quotation.language}.png`)"
            alt="Quelli della Pizza"
            class="mb-5"
          >
        </v-flex>
        <v-flex
          xs12
          class="text-xs-center"
        >
          <v-progress-circular
            v-if="spinner"
            indeterminate
            color="primary"
          />
        </v-flex>
      </v-layout>
    </v-container>

    <v-container
      v-if="spinner === false"
      fluid
    >
      <v-layout
        row
        wrap
        align-center
      >
        <v-flex
          xs6
          class="text-xs-left"
        >
          <h5>{{ new Date(quotation.updated).toLocaleDateString('it-IT') }}</h5>
        </v-flex>
        <v-flex
          xs6
          class="text-xs-right"
        >
          <h5
            style="font-weight: normal; margin-bottom: 5px;"
          >
            {{ this.$store.state.translation[quotation.language].dear }}
          </h5>
          <h4 v-if="quotation.name">
            {{ quotation.name }}
          </h4>
          <h5 style="font-weight: normal;">
            {{ quotation.company }}
          </h5>
        </v-flex>

        <v-flex
          xs12
          class="text-xs-left"
        >
          <h2 style="margin-top: 40px;">
            {{ this.$store.state.translation[quotation.language].subject }}:
            <span
              style="font-weight: normal;"
            >{{ this.$store.state.translation[quotation.language].title }}</span>
          </h2>
        </v-flex>

        <!-- break -->
        <v-flex xs12 />

        <!-- List -->

        <!-- Loop for every item in the quotation -->
        <v-flex xs12>
          <product
            v-for="(item, index) in quotation.products"
            :key="item.id"
            :item="item"
            :language="quotation.language"
            :price-list="quotation.priceList"
            :index="index"
            class="mt-2"
          />

          <!-- Shipping -->
          <v-flex
            xs12
            class="text-xs-left"
          />
          <v-container
            grid-list-md
            text-xs-center
          >
            <v-layout
              row
              wrap
            >
              <v-flex xs12 />
            </v-layout>
          </v-container>
        </v-flex>

        <!-- Min Order -->
        <v-flex
          xs12
          class="text-xs-left"
        >
          <h2>{{ this.$store.state.translation[quotation.language].minOrder }}:</h2>
          <p v-html="brizer(minOrder())" />
        </v-flex>

        <!-- Shipping -->
        <v-flex
          xs12
          class="text-xs-left"
        >
          <h2>{{ this.$store.state.translation[quotation.language].shipping }}:</h2>
          <p v-html="brizer(shipping())" />
        </v-flex>

        <!-- Production Times -->
        <v-flex
          xs12
          class="text-xs-left"
        >
          <h2>{{ this.$store.state.translation[quotation.language].production }}:</h2>
          <p v-html="brizer(lead())" />
        </v-flex>

        <!-- Payment -->
        <v-flex
          xs12
          class="text-xs-left"
        >
          <h2>{{ this.$store.state.translation[quotation.language].payment }}:</h2>
          <p v-html="brizer(payment())" />
        </v-flex>

        <!-- Notes -->
        <v-flex
          xs12
          class="text-xs-left"
        >
          <h2>{{ this.$store.state.translation[quotation.language].note }}:</h2>
          <p v-html="brizer(quotation.note)" />
          <p>{{ validity() }}</p>
          <p v-html="brizer(this.$store.state.translation[quotation.language].extranote.prices)" />
        </v-flex>

        <!-- Signature -->
        <v-flex
          v-if="print === true"
          xs12
          class="text-xs-right"
        >
          <table style="margin-top: 20px; text-align: right; width: 100%;">
            <tr>
              <td>
                <b>{{ this.$store.state.translation[quotation.language].perAccettazione }}</b>
                <br>
                <br>
                <br>___________________________________
                <br>
                ({{
                  this.$store.state.translation[quotation.language].ilCliente
                }})
              </td>
            </tr>
          </table>
        </v-flex>

        <!-- download PDF -->
        <v-flex
          v-if="print === false"
          xs12
          class="text-xs-center"
        >
          <v-btn
            :href="`${this.$store.state.baseUrl}/api/pdf/${this.$route.params.id}?language=${quotation.language}`"
            target="_blank"
            dark
            large
            color="primary"
          >
            <v-icon dark>
              get_app
            </v-icon>Scarica il PDF
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
// import axios from 'axios';
import authenticated from '../components/Authenticated'
import product from '../components/Product'
import axios from 'axios'
import _ from 'lodash'

export default {
  name: 'Render',
  components: {
    authenticated,
    product,
  },
  data () {
    return {
      print: false,
      quotation: {},
      headers: [
        {
          text: 'Nome',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Quantita',
          align: 'left',
          sortable: false,
          value: 'quantity',
        },
        {
          text: 'Descrizione',
          align: 'left',
          sortable: false,
          value: 'description',
        },
        {
          text: 'Prezzo',
          align: 'left',
          sortable: false,
          value: 'price',
        },
      ],
      spinner: false,

      apiheaders: {
        username: _.get(this.$session.get('user'), 'name', null),
        token: _.get(this.$session.get('user'), 'token', null),
        pdf: null,
      },
    }
  },
  watch: {
    $route: 'fetch',
  },
  created () {
    this.print = this.$route.query.pdf === 'true'
    this.fetch()
  },
  methods: {
    validity () {
      const data = new Date(this.quotation.updated).toLocaleDateString('it-IT')
      return this.$store.state.translation[this.quotation.language].extranote.validity.replace('$data_offerta', data)
    },
    lead () {
      if (this.quotation.lead === 'fivetoten') {
        return this.$store.state.translation[this.quotation.language].leadopt.fiveten
      } else if (this.quotation.lead === 'tentofiftheen') {
        return this.$store.state.translation[this.quotation.language].leadopt.tentofiftheen
      } else {
        return this.quotation.lead
      }
    },
    payment () {
      if (this.quotation.payment === 'concordare') {
        return this.$store.state.translation[this.quotation.language].paymentopt.tbd
      } else if (this.quotation.payment === 'banktransfer') {
        return this.$store.state.translation[this.quotation.language].paymentopt.banktransfer
      } else if (this.quotation.payment === 'banktransfer30ggdf') {
        return this.$store.state.translation[this.quotation.language].paymentopt.banktransfer30ggdf
      } else {
        return this.quotation.payment
      }
    },
    shipping () {
      if (this.quotation.shipping === 'exworks') {
        return this.$store.state.translation[this.quotation.language].transport.exworks
      } else if (this.quotation.shipping === 'incluso') {
        return this.$store.state.translation[this.quotation.language].transport.included
      } else {
        return this.quotation.shipping
      }
    },
    brizer (text) {
      return text.replace(/(?:\r\n|\r|\n)/g, '<br>')
    },
    minOrder () {
      if (this.quotation.minOrder === 'mixed') {
        return this.$store.state.translation[this.quotation.language].minOrderopt.mixed
      } else if (this.quotation.minOrder === 'reference') {
        return this.$store.state.translation[this.quotation.language].minOrderopt.reference
      } else {
        return this.quotation.minOrder
      }
    },
    fetch () {
      if (this.$route.query.pdf === true) {
        this.apiheaders.pdf = true
      }

      // get id from url
      if (this.$route.params.id === null) {
        return
      }
      this.spinner = true
      axios({
        method: 'GET',
        url: `${this.$store.state.baseUrl}/api/quotation/${this.$route.params.id}`,
        headers: this.apiheaders,
      })
        .then((resp) => {
          if (resp.status === 200) {
            this.quotation = resp.data
            console.log(resp.data)
          }
          this.spinner = false
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          alert(err)
        })
    },
  },
}
</script>

<style scoped>
div.tabellaProdotto {
  page-break-after: always;
}
</style>
