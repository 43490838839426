import '@babel/polyfill'
import Vue from 'vue'
import './plugins/axios'
import './plugins/vuetify'
import './plugins/currency'
import './plugins/moment'
import App from './App.vue'
import router from './router'
import {
  Store
} from './store/store'

Vue.config.productionTip = false

new Vue({
  router,
  store: Store,
  render: h => h(App)
}).$mount('#app')
