import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const Store = new Vuex.Store({
  state: {
    baseUrl: 'https://preventivatore.herokuapp.com',
    //baseUrl: 'http://localhost:3000',
    translation: {
      'Italiano': {
        dear: 'Spett.le',
        subject: 'Oggetto',
        title: 'Preventivo basi per pizza',
        shipping: 'Spedizione',
        payment: 'Pagamento',
        minOrder: 'Ordine Minimo',
        production: 'Lead time ed evasione ordine',
        note: 'Note',
        tmcTitle: 'Scadenza garantita alla consegna',
        days: 'giorni',
        months: 'mesi',
        monthsfromprod: 'mesi dalla data di produzione',
        shelflife: 'Shelf Life',
        product: {
          tmc: '$result $length',
          handling: 'Conservazione',
          size: 'Diametro o formato',
          pallet: 'Pallet ($type)',
          americanPallet: 'GMA Pallet (100x120cm)',
          description: 'Descrizione',
          weight: 'Peso',
          pdf: 'Scheda Tecnica',
          download: 'Scarica PDF',
          packaging: 'Confezionamento (EPAL)',
          packagingAmerican: 'Confezionamento (GMA Pallet)',
          alPezzo: 'per pizza',
          alBox: 'al box',
          price: {
            original: 'Listino',
            discounted: 'Prezzo a voi riservato'
          }
        },
        handling: {
          atm: 'Confezionata in atmosfera modificata. Da conservare in Frigo (+4°C) con una durata di $grossisti_durata giorni dalla data di produzione.',
          gelo: 'Surgelata da conservare in Freezer (-18°C) con durata $grossisti_durata mesi dalla data di produzione.',
          gelo_in_atm: 'Conservare a temperature di -18/ -22°C. Da utilizzare dopo lo scongelamento. Una volta scongelato, non ricongelare, conservare in Frigo (+4°C) e consumare entro 30 giorni.',
          asciutto: 'Da conservare in luogo fresco e asciutto con una durata di $grossisti_durata giorni dalla data di produzione.',
          ambiente: 'Da conservare in luogo fresco e asciutto con una durata di $grossisti_durata mesi dalla data di produzione.'
        },
        transport: {
          exworks: 'L\'offerta è da considerarsi ex-works (trasporto escluso).',
          included: 'Trasporto gratuito incluso.'
        },
        paymentopt: {
          tbd: 'Da concordare.',
          banktransfer: 'Pagamento tramite bonifico bancario anticipato.',
          banktransfer30ggdf: 'Bonifico bancario 30 giorni data fattura. Previa autorizzazione della compagnia di assicurazione del credito Coface',
        },
        minOrderopt: {
          mixed: '1 pallet anche misto.',
          reference: '1 pallet. Il pallet non può contenere prodotti misti.',
        },
        leadopt: {
          fiveten: 'Da 5 a 10 giorni lavorativi.',
          tentofiftheen: 'Da 10 a 15 giorni lavorativi.'
        },
        pallet: 'Ogni pallet contiene $grossisti_pallet scatole per un totale di $totale pizze.',
        packaging: 'Ogni scatola contiene $pezzidiv buste con $pezzibusta pizze ciascuna. $pezzicartone pizze per scatola.',
        extranote: {
          validity: 'L\'offerta è valida per 30 giorni dalla data $data_offerta.',
          prices: 'Tutti i prezzi sono da intendersi IVA esclusa.'
        },
        prezzoCartone: 'Prezzo a cartone',
        perAccettazione: 'FIRMA PER ACCETTAZIONE',
        ilCliente: 'IL CLIENTE'
      },
      'Francese': {
        dear: 'Cher / Chère',
        subject: 'Sujet',
        title: 'Devis Fonds de Pizza',
        shipping: 'Expédition',
        payment: 'Paiement',
        minOrder: 'Commande minimum',
        production: 'Délais de traitement des commandes',
        note: 'Notes',
        tmcTitle: 'Expiration garantie à la livraison',
        days: 'jours',
        months: 'mois',
        monthsfromprod: 'mois à partir de la date de production',
        shelflife: 'Shelf Life',
        product: {
          tmc: '$result $length',
          handling: 'Préservation',
          size: 'Diamètre ou taille',
          pallet: 'Pallet ($type)',
          americanPallet: 'GMA Pallet (100x120cm)',
          description: 'Description',
          weight: 'Poids',
          pdf: 'Fiche technique',
          download: 'Télécharger le PDF',
          packaging: 'Emballage (EPAL)',
          packagingAmerican: 'Emballage (GMA Pallet)',
          alPezzo: 'par pizza',
          alBox: 'al box',
          price: {
            original: 'Prix public',
            discounted: 'Prix réservé pour vous'
          }
        },
        transport: {
          exworks: 'L\'offre ne comprend pas le transport.',
          included: 'Transport gratuit inclus'
        },
        paymentopt: {
          tbd: 'À convenir',
          banktransfer: 'Paiement par virement bancaire à l\'avance.',
          banktransfer30ggdf: 'Virement bancaire à 30 jours, date de facturation. Après approbation de la compagnie d\'assurance-crédit Coface',
        },
        minOrderopt: {
          mixed: '1 palette complète panachée',
          reference: '1 palette. La palette ne peut pas contenir de produits mélangés.',
        },
        leadopt: {
          fiveten: 'De 5 à 10 jours ouvrables.',
          tentofiftheen: 'De 10 à 15 jours ouvrables.'
        },
        handling: {
          atm: 'Emballé dans une atmosphère modifiée. A conserver au Réfrigérateur (+4°). Durée $grossisti_durata jours à partir de la date de production.',
          gelo: 'Congelé à conserver au congélateur (-18°C). Durée $grossisti_durata mois à partir de la date de production.',
          gelo_in_atm: 'Conserver à une température de -18/-22 °C. A utiliser après décongélation. Une fois décongelé, ne pas recongeler, conserver au réfrigérateur et utiliser dans les 30 jours.',
          asciutto: 'À conserver dans un endroit frais et sec d\'une durée de $grossisti_durata journées à compter de la date de production.',
          ambiente: 'À conserver dans un endroit frais et sec d\'une durée de $grossisti_durata mois à compter de la date de production.'
        },
        pallet: 'Chaque palette contient $grossisti_pallet boîtes pour un total de $totale pizzas.',
        packaging: 'Chaque boîte contient $pezzidiv enveloppes à partir de $pezzibusta pizzas chacun. Chaque boîte contient $pezzicartone pizzas.',
        extranote: {
          validity: 'L\'offre est valable 30 jours à partir du $data_offerta.',
          prices: 'Tous les prix sont hors TVA.'
        },
        prezzoCartone: 'Prix par carton',
        perAccettazione: 'SIGNATURE D\'ACCEPTATION',
        ilCliente: 'LE CLIENT'
      },
      'Inglese': {
        dear: 'Dear',
        subject: 'Subject',
        title: 'Pizza Bases quote',
        shipping: 'Shipping',
        payment: 'Payment',
        production: 'Lead time and production times',
        minOrder: 'Minimum order',
        note: 'Notes',
        tmcTitle: 'Expiry guaranteed on delivery',
        days: 'days',
        months: 'months',
        monthsfromprod: 'months from production date',
        shelflife: 'Shelf Life',
        product: {
          tmc: '$result $length',
          handling: 'Conservation',
          size: 'Diameter or size',
          pallet: 'Pallet ($type)',
          americanPallet: 'GMA Pallet (100x120cm)',
          description: 'Description',
          weight: 'Weight',
          pdf: 'Techical data sheet',
          download: 'Download PDF',
          packaging: 'Packaging (EPAL)',
          packagingAmerican: 'Packaging (GMA Pallet)',
          alPezzo: 'per pizza',
          alBox: 'al box',
          price: {
            original: 'Price list',
            discounted: 'Price reserved for you'
          }
        },
        transport: {
          exworks: 'The offer is to be considered ex-works.',
          included: 'Free shipping included.'
        },
        paymentopt: {
          tbd: 'To be agreed.',
          banktransfer: 'Bank transfer in advance.',
          banktransfer30ggdf: 'Bank transfer 30 days from invoice date. Subject to the authorization of the credit insurance company Coface.',
        },
        minOrderopt: {
          mixed: '1 pallet. The pallet can contain mixed products.',
          reference: '1 pallet. The pallet can\'t contain mixed products.',
        },
        leadopt: {
          fiveten: 'From 5 to 10 working days.',
          tentofiftheen: 'From 10 to 15 working days.'
        },
        handling: {
          atm: 'Packed in modified atmosphere packaging. To be stored in Fridge (+4°C / +39.2°F) with a shelf-life of $grossisti_durata days from production date.',
          gelo: 'Frozen to be stored in freezer (-18°C / -0.4°F) with a shelf-life of $grossisti_durata months from production date.',
          gelo_in_atm: 'Store at -18°/-22° C (-0,4°/-7,6° F). To be used after thawing. Once defrosted do not refreeze, keep refrigerated and use within 30 days.',
          asciutto: 'To be kept in a cool and dry place with a shelf-life of $grossisti_durata days from production date.',
          ambiente: 'To be kept in a cool and dry place with a shelf-life of $grossisti_durata months from production date.'
        },
        pallet: 'Every pallet contains $grossisti_pallet boxes for a total of $totale pizzas.',
        packaging: 'Every box contains $pezzidiv envelopes with $pezzibusta pizzas each. $pezzicartone pizzas per box.',
        extranote: {
          validity: 'The offer is valid for 30 days from the date $data_offerta.',
          prices: 'All prices are VAT excluded.'
        },
        prezzoCartone: 'Price per box',
        perAccettazione: 'SIGNATURE FOR ACCEPTANCE',
        ilCliente: 'THE CUSTOMER'
      },
      'Spagnolo': {
        dear: 'Estimado',
        subject: 'Objecto',
        title: 'Presupuestos para bases de pizza',
        shipping: 'Envío',
        payment: 'Pago',
        minOrder: 'Orden minima',
        production: 'Plazo de entrega y cumplimiento de pedidos',
        note: 'Notas',
        tmcTitle: 'Plazo de entrega garantizado',
        days: 'días',
        months: 'meses',
        monthsfromprod: 'mesi dalla data di produzione',
        shelflife: 'Shelf Life',
        product: {
          tmc: '$result $length',
          handling: 'Conservación',
          size: 'Diámetro o tamaño',
          pallet: 'Pallet ($type)',
          americanPallet: 'GMA Pallet (100x120cm)',
          description: 'Descripción',
          weight: 'Peso',
          pdf: 'Ficha técnica',
          download: 'Descargar PDF',
          packaging: 'Embalaje (EPAL)',
          packagingAmerican: 'Embalaje (GMA Pallet)',
          alPezzo: 'para pizza',
          alBox: 'al box',
          price: {
            original: 'Lista',
            discounted: 'Precio reservado para usted'
          }
        },
        handling: {
          atm: 'Empaquetado en atmósfera modificada. Se mantendrá en Frigo (+ 4 ° C) con una duración de $grossisti_durata días a partir de la fecha de producción.',
          gelo: 'Congelado para mantener en congelador (-18 ° C) con una duración de $grossisti_durata meses a partir de la fecha de producción.',
          gelo_in_atm: 'Conservar a temperatura entre -18 °C y -22 °C. Para utilizar después de descongelar. Una vez descongelado, no vuelva a congelar, manténgalo refrigerado y úselo dentro de las 30 días.',
          asciutto: 'Para mantenerse en un lugar fresco y seco con una duración de $grossisti_durata días a partir de la fecha de producción.',
          ambiente: 'Para mantenerse en un lugar fresco y seco con una duración de $grossisti_durata meses a partir de la fecha de producción.'
        },
        transport: {
          exworks: 'La oferta debe ser considerada en fábrica (transporte excluido).',
          included: 'Transporte gratuito incluido.'
        },
        paymentopt: {
          tbd: 'A convenir.',
          banktransfer: 'Pago por transferencia bancaria por adelantado.',
          banktransfer30ggdf: 'Transferencia bancaria 30 días a partir de la fecha de facturación. Sujeto a la autorización de la compañía de seguros de crédito Coface.',
        },
        minOrderopt: {
          mixed: '1 palet también mezclado.',
          reference: '1 palet. El palet no puede contener productos mixtos.',
        },
        leadopt: {
          fiveten: 'De 5 a 10 días laborables.',
          tentofiftheen: 'De 10 a 15 días laborables.',
        },
        pallet: 'Cada palet contiene $grossisti_pallet cajas para un total de $totale pizzas.',
        packaging: 'Cada caja contiene $pezzidiv bolsas con $pezzibusta pizzas cada una. $pezzicartone pizzas por caja.',
        extranote: {
          validity: 'La oferta es válida por 30 días a partir del $data_offerta.',
          prices: 'Todos los precios están excluidos del IVA.'
        },
        prezzoCartone: 'Precio por caja',
        perAccettazione: 'FIRMA DE ACEPTACIÓN',
        ilCliente: 'EL CLIENTE'
      },
      'Tedesco': {
        dear: 'Firma',
        subject: 'Betreff',
        title: 'Angebot für Pizzaböden',
        shipping: 'Versand',
        payment: 'Zahlung',
        minOrder: 'Mindestauftrag',
        production: 'Durchlaufzeit und Auftragslieferung',
        note: 'Anmerkungen',
        tmcTitle: 'Bei der Lieferung garantiertes Ablaufdatum',
        days: 'tage',
        months: 'monate',
        monthsfromprod: 'Monaten ab Produktionsdatum lagern',
        shelflife: 'Shelf Life',
        product: {
          tmc: '$result $length',
          handling: 'Erhaltung',
          size: 'Durchmesser oder Größe',
          pallet: 'Pallet ($type)',
          americanPallet: 'GMA Pallet (100x120cm)',
          description: 'beschreibung',
          weight: 'gewicht',
          pdf: 'technisches Datenblatt',
          download: 'PDF herunterladen',
          packaging: 'Verpackung (EPAL)',
          packagingAmerican: 'Verpackung (GMA Pallet)',
          alPezzo: 'für Pizzen',
          alBox: 'al box',
          price: {
            original: 'Preisliste',
            discounted: 'Sonderpreis für Sie'
          }
        },
        handling: {
          atm: 'Unter modifizierter Atmosphäre verpackt. Im Kühlschrank (+4 °C) mit einer Haltbarkeit von $grossisti_durata Tagen ab Produktionsdatum aufbewahren.',
          gelo: 'Im gefrorenen Zustand im Gefrierschrank (-18 °C) mit einer Haltbarkeit von $grossisti_durata Monaten ab Produktionsdatum lagern.',
          gelo_in_atm: 'Bei Temperaturen zwischen -18 und -22 °C lagern. Nach dem Auftauen zu verwenden. Nach dem Auftauen nicht wieder einfrieren, im Kühlschrank aufbewahren und innerhalb von 30 Tage verbrauchen.',
          asciutto: 'An einem kühlen, trockenen Ort mit einer Haltbarkeit von $grossisti_durata Tagen ab Produktionsdatum lagern.',
          ambiente: 'An einem kühlen, trockenen Ort mit einer Haltbarkeit von $grossisti_durata Monaten ab Produktionsdatum lagern.'
        },
        transport: {
          exworks: 'Das Angebot gilt ab Werk (ohne Transport).',
          included: 'kostenloser Transport inklusive.'
        },
        paymentopt: {
          tbd: 'zu vereinbaren.',
          banktransfer: 'Zahlung per Banküberweisung im Voraus.',
          banktransfer30ggdf: 'Überweisung 30 Tage ab Rechnungsdatum. Vorbehaltlich der Genehmigung der Kreditversicherungsgesellschaft Coface.',
        },
        minOrderopt: {
          mixed: '1 Palette, auch gemischt.',
          reference: '1 Palette Die Palette kann keine gemischten Produkte enthalten.',
        },
        leadopt: {
          fiveten: '5 bis 10 WerktaFge.',
          tentofiftheen: '10 bis 15 WerktaFge.',
        },
        pallet: 'Jede Palette enthält $grossisti_pallet Kartons mit insgesamt $totale Pizzen.',
        packaging: 'Jeder Karton enthält $pezzidiv Tüten mit je $pezzibusta Pizzen. $pezzicartone Pizzen pro Karton.',
        extranote: {
          validity: 'Das Angebot ist ab dem $data_offerta 30 Tage lang gültig.',
          prices: 'Alle Preise verstehen sich zzgl. gesetzlicher MwSt.'
        },
        prezzoCartone: 'Preis pro Box',
        perAccettazione: 'UNTERZEICHNUNG ZUR ANNAHME',
        ilCliente: 'DER KUNDE'
      },
    },
  },
})
