<template>
  <v-card>
    <authenticated />

    <v-card-title v-if="this.$route.params.id">
      Modifica un preventivo
    </v-card-title>
    <v-card-title v-else>
      Crea un nuovo preventivo
    </v-card-title>

    <v-alert
      :value="error !== null"
      color="error"
    >
      {{ error }}
    </v-alert>

    <v-stepper
      v-model="step"
      vertical
    >
      <!-- step 1 languauge -->
      <v-stepper-step
        :complete="step > 1"
        step="1"
        editable
      >
        Lingua
        <small>Scegli la lingua del preventivo</small>
      </v-stepper-step>
      <v-stepper-content step="1">
        <v-container fluid>
          <v-layout
            row
            wrap
            align-center
          >
            <v-flex xs6>
              <v-select
                v-model="quotation.language"
                :items="languages"
                auto
                label="Seleziona"
                hide-details
                prepend-icon="flag"
                single-line
              />
            </v-flex>
          </v-layout>
        </v-container>

        <v-btn
          color="primary"
          @click="validate(2)"
        >
          Continua
        </v-btn>
      </v-stepper-content>

      <!-- step 2 price list -->
      <v-stepper-step
        :complete="step > 2"
        step="2"
        editable
      >
        Listino
        <small>Scegli il listino prezzi</small>
      </v-stepper-step>
      <v-stepper-content step="2">
        <v-container fluid>
          <v-layout
            row
            wrap
            align-center
          >
            <v-flex xs6>
              <v-select
                v-model="quotation.priceList"
                :items="priceList"
                auto
                label="Seleziona"
                hide-details
                prepend-icon="flag"
                single-line
              />
            </v-flex>
          </v-layout>
        </v-container>

        <v-btn
          color="primary"
          @click="submit(true, 3)"
        >
          Continua
        </v-btn>
        <v-btn
          flat
          @click="step = 1"
        >
          Indietro
        </v-btn>
      </v-stepper-content>
      <!-- step 3 customer -->
      <v-stepper-step
        :complete="step > 3"
        step="3"
        editable
      >
        Cliente
        <small>Definisci il cliente per il preventivo</small>
      </v-stepper-step>
      <v-stepper-content step="3">
        <v-container fluid>
          <v-layout
            row
            wrap
            align-center
          >
            <v-flex xs6>
              <v-text-field
                v-model="quotation.company"
                label="Ragione Sociale*"
                required
              />
              <v-text-field
                v-model="quotation.name"
                label="Nome Cognome"
                required
              />
            </v-flex>
          </v-layout>
        </v-container>

        <v-btn
          color="primary"
          @click="validate(4)"
        >
          Continua
        </v-btn>
        <v-btn
          flat
          @click="step = 2"
        >
          Indietro
        </v-btn>
      </v-stepper-content>

      <!-- step 4 product -->
      <v-stepper-step
        :complete="step > 4"
        step="4"
        editable
      >
        Prodotti
        <small>Seleziona i prodotti che saranno presenti nel preventivo</small>
      </v-stepper-step>
      <v-stepper-content step="4">
        <div>
          <v-btn
            color="primary"
            dark
            @click.stop="productPicker = true"
          >
            <v-icon dark>
              add
            </v-icon>Aggiungi prodotto
          </v-btn>

          <v-data-table
            :headers="headers"
            :items="quotation.products"
            hide-actions
          >
            <template
              slot="items"
              slot-scope="props"
            >
              <td class="text-xs-left">
                {{ props.item.title }}
              </td>
              <td class="text-xs-right">
                {{ props.item.sku }}
              </td>
              <td class="text-xs-right">
                <img
                  v-if="props.item.thumbnails != undefined"
                  :src="props.item.thumbnails[props.item.thumbnails.length - 1]"
                  height="80"
                >
              </td>
              <td class="text-xs-right">
                {{ pricePerItem(props.item) | currency }}
              </td>
              <td class="text-xs-right">
                <v-edit-dialog
                  :return-value.sync="props.item.discounted"
                  large
                  lazy
                  persistent
                  @save="save"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                >
                  <div>{{ props.item.discounted | currency }}</div>
                  <div
                    slot="input"
                    class="mt-3 title"
                  >
                    Definisci prezzo finale
                  </div>
                  <v-text-field
                    slot="input"
                    v-model="props.item.discounted"
                    label="Modifica"
                    single-line
                    autofocus
                  />
                </v-edit-dialog>
              </td>
              <td class="text-xs-right">
                <v-edit-dialog
                  :return-value.sync="props.item.off"
                  large
                  lazy
                  persistent
                  @save="save"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                >
                  <div>{{ props.item.off }} %</div>
                  <div
                    slot="input"
                    class="mt-3 title"
                  >
                    Definisci lo sconto da applicare
                  </div>
                  <v-text-field
                    slot="input"
                    v-model="props.item.off"
                    label="Modifica"
                    single-line
                    counter
                    autofocus
                  />
                </v-edit-dialog>
              </td>
              <td class="text-xs-right">
                <v-edit-dialog
                  :return-value.sync="props.item.note"
                  large
                  lazy
                  persistent
                  @save="save"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                >
                  <div>{{ props.item.note }}</div>
                  <div
                    slot="input"
                    class="mt-3 title"
                  >
                    Aggiungi una nota
                  </div>
                  <v-textarea
                    slot="input"
                    v-model="props.item.note"
                    label="Salva"
                    counter
                    autofocus
                  />
                </v-edit-dialog>
              </td>
              <td class="text-xs-right">
                <v-tooltip bottom>
                  <v-icon
                    slot="activator"
                    small
                    class="mr-2"
                    @click="deleteItem(props.item)"
                  >
                    delete
                  </v-icon>
                  <span>Cancella</span>
                </v-tooltip>
              </td>
            </template>
            <template
              slot="pageText"
              slot-scope="{ pageStart, pageStop }"
            >
              From {{ pageStart }} to {{ pageStop }}
            </template>
          </v-data-table>

          <v-snackbar
            v-model="snack"
            :timeout="3000"
            :color="snackColor"
          >
            {{ snackText }}
            <v-btn
              flat
              @click="snack = false"
            >
              Chiudi
            </v-btn>
          </v-snackbar>
        </div>

        <v-btn
          color="primary"
          @click="submit(true, 5)"
        >
          Continua
        </v-btn>
        <v-btn
          flat
          @click="step = 3"
        >
          Indietro
        </v-btn>

        <v-dialog
          v-model="productPicker"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          scrollable
        >
          <picker
            :price-list="quotation.priceList"
            @closePicker="productPicker = $event"
            @selectedItem="addProduct($event)"
          />
        </v-dialog>
      </v-stepper-content>

      <!-- step 5 transport -->
      <v-stepper-step
        :complete="step > 5"
        step="5"
        editable
      >
        Trasporto
        <small>Definisci il metodo di trasporto</small>
      </v-stepper-step>
      <v-stepper-content step="5">
        <v-container fluid>
          <v-layout
            row
            wrap
            align-center
          >
            <v-flex xs6>
              <v-radio-group
                v-model="quotation.shipping.method"
                row
              >
                <v-radio
                  :value="options.exworks"
                  color="primary"
                  label="Ex-Works"
                />
                <v-radio
                  :value="options.included"
                  color="primary"
                  label="Incluso"
                />
                <v-radio
                  :value="options.note"
                  color="primary"
                  label="Nota"
                />
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-container>

        <v-container
          v-if="quotation.shipping.method == options.note"
          fluid
          grid-list-md
        >
          <v-textarea
            v-model="quotation.shipping.note"
            box
            label="Note"
            auto-grow
          />
        </v-container>

        <v-btn
          color="primary"
          @click="submit(true, 6)"
        >
          Continua
        </v-btn>
        <v-btn
          flat
          @click="step = 4"
        >
          Indietro
        </v-btn>
      </v-stepper-content>

      <!-- step 6 payment -->
      <v-stepper-step
        :complete="step > 6"
        step="6"
        editable
      >
        Pagamento
        <small>Definisci il metodo di pagamento</small>
      </v-stepper-step>
      <v-stepper-content step="6">
        <v-container fluid>
          <v-layout
            row
            wrap
            align-center
          >
            <v-flex xs6>
              <v-radio-group
                v-model="quotation.payment.method"
                row
              >
                <v-radio
                  :value="options.tbd"
                  color="primary"
                  label="Da Concordare"
                />
                <v-radio
                  :value="options.banktransfer"
                  color="primary"
                  label="Bonifico Anticipato"
                />
                <v-radio
                  :value="options.banktransfer30ggdf"
                  color="primary"
                  label="Bonifico 30 ggdf"
                />
                <v-radio
                  :value="options.note"
                  color="primary"
                  label="Nota"
                />
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-container>

        <v-container
          v-if="quotation.payment.method == options.note"
          fluid
          grid-list-md
        >
          <v-textarea
            v-model="quotation.payment.note"
            box
            label="Note"
            auto-grow
          />
        </v-container>

        <v-btn
          color="primary"
          @click="submit(true, 7)"
        >
          Continua
        </v-btn>
        <v-btn
          flat
          @click="step = 5"
        >
          Indietro
        </v-btn>
      </v-stepper-content>

      <!-- step 7 min order -->
      <v-stepper-step
        :complete="step > 7"
        step="7"
        editable
      >
        Ordine minimo
        <small>Definisci l'ordine minimo</small>
      </v-stepper-step>

      <v-stepper-content step="7">
        <v-container fluid>
          <v-layout
            row
            wrap
            align-center
          >
            <v-flex xs6>
              <v-radio-group
                v-model="quotation.minOrder.method"
                row
              >
                <v-radio
                  :value="options.reference"
                  color="primary"
                  label="1 pallet per referenza"
                />
                <v-radio
                  :value="options.mixed"
                  color="primary"
                  label="1 pallet misto"
                />
                <v-radio
                  :value="options.note"
                  color="primary"
                  label="Nota"
                />
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-container>

        <v-container
          v-if="quotation.minOrder.method == options.note"
          fluid
          grid-list-md
        >
          <v-textarea
            v-model="quotation.minOrder.note"
            box
            label="Note"
            auto-grow
          />
        </v-container>
        <v-btn
          color="primary"
          @click="submit(true, 8)"
        >
          Continua
        </v-btn>
        <v-btn
          flat
          @click="step = 6"
        >
          Indietro
        </v-btn>
      </v-stepper-content>

      <!-- step 8 lead time -->
      <v-stepper-step
        :complete="step > 8"
        step="8"
        editable
      >
        Lead Time ed evasione ordine
      </v-stepper-step>
      <v-stepper-content step="8">
        <v-container fluid>
          <v-layout
            row
            wrap
            align-center
          >
            <v-flex xs6>
              <v-radio-group
                v-model="quotation.lead.method"
                row
              >
                <v-radio
                  :value="options.fivetoten"
                  color="primary"
                  label="Da 5 a 10 giorni lavorativi"
                />
                <v-radio
                  :value="options.tentofiftheen"
                  color="primary"
                  label="Da 10 a 15 giorni lavorativi"
                />
                <v-radio
                  :value="options.note"
                  color="primary"
                  label="Nota"
                />
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-container>

        <v-container
          v-if="quotation.lead.method == options.note"
          fluid
          grid-list-md
        >
          <v-textarea
            v-model="quotation.lead.note"
            box
            label="Note"
            auto-grow
          />
        </v-container>
        <v-btn
          color="primary"
          @click="submit(true, 9)"
        >
          Continua
        </v-btn>
        <v-btn
          flat
          @click="step = 7"
        >
          Indietro
        </v-btn>
      </v-stepper-content>

      <!-- step 9 note -->
      <v-stepper-step
        :complete="step > 9"
        step="9"
        editable
      >
        Ulteriori note
        <small>Definisci eventuali altri note</small>
      </v-stepper-step>
      <v-stepper-content step="9">
        <v-container
          fluid
          grid-list-md
        >
          <v-textarea
            v-model="quotation.note"
            box
            label="Note"
            auto-grow
          />
        </v-container>
        <v-btn
          color="primary"
          @click="submit(false, 10)"
        >
          Salva
        </v-btn>
        <v-btn
          flat
          @click="step = 8"
        >
          Indietro
        </v-btn>
      </v-stepper-content>
    </v-stepper>
  </v-card>
</template>

<script>
import authenticated from '../components/Authenticated'
import picker from '../components/Picker'
import axios from 'axios'
import _ from 'lodash'

export default {
  components: {
    authenticated,
    picker,
  },
  data () {
    return {
      error: null,
      productPicker: false,
      id: null,
      options: {
        exworks: 'exworks',
        included: 'incluso',
        tbd: 'concordare',
        banktransfer: 'banktransfer',
        banktransfer30ggdf: 'banktransfer30ggdf',
        note: 'nota',
        mixed: 'mixed',
        reference: 'reference',
        fivetoten: 'fivetoten',
        tentofiftheen: 'tentofiftheen'
      },
      apiheaders: {
        username: this.$session.get('user').name,
        token: this.$session.get('user').token,
      },
      quotation: {
        language: '',
        priceList: '',
        name: '',
        company: '',
        shipping: {
          method: '',
          note: '',
        },
        payment: {
          method: '',
          note: '',
        },
        minOrder: {
          method: '',
          note: '',
        },
        lead: {
          method: '',
          note: '',
        },
        note: '',
        products: [],
        draft: true,
      },
      languages: ['Italiano', 'Inglese', 'Francese', 'Tedesco', 'Spagnolo'],
      priceList: ['Italiano', 'Straniero'],
      step: 1,
      snack: false,
      snackColor: '',
      snackText: '',
      pagination: {},
      headers: [
        {
          text: 'Nome',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Codice',
          sortable: true,
          value: 'sku',
        },
        {
          text: 'Foto',
          sortable: false,
        },
        {
          text: 'Prezzo Listino',
          sortable: false,
        },
        {
          text: 'Prezzo',
          sortable: false,
        },
        {
          text: 'Sconto',
          sortable: false,
        },
        {
          text: 'Note',
          sortable: false,
        },
        {
          text: 'Azioni',
          sortable: false,
        },
      ],
    }
  },

  watch: {
    $route: 'fetch',
  },
  created () {
    this.fetch()
  },
  methods: {
    validate (s) {
      if (s === 2) {
        if (this.quotation.language !== '') {
          this.error = null
          this.submit(true, s)
        } else {
          this.error = 'Selezionare una lingua per poter procedere'
        }
      } else if (s === 4) {
        if (this.quotation.company !== '') {
          this.submit(true, s)
          this.error = null
        } else {
          this.error = 'Popolare il campo Ragione Sociale per procedere'
        }
      }
    },
    save () {},
    cancel () {},
    open () {
      this.snack = false
    },
    close () {
      // console.log('Dialog closed')
    },
    pricePerItem (item) {
      const data = _.find(item.attributes, { name: 'Pezzi a cartone' })
      const value = _.get(data, 'value', '')
      let price = parseFloat(item.priceRegular)
      if (this.quotation.priceList === "Straniero"){
        const data = _.find(item.attributes, { name: 'listino_estero' })
        const value = _.get(data, 'value') 
        if (value !== undefined && value !== ''){
          price = parseFloat(value)
        }
      }
      if (this.quotation.priceList === "Frozen"){
        const dataF = _.find(item.attributes, { name: 'listino_frozen' })
        const valueF = _.get(dataF, 'value') 
        if (valueF !== undefined && valueF !== ''){
          price = parseFloat(valueF)
        }
      }
      return parseFloat(price / value)
    },
    deleteItem (item) {
      const index = this.quotation.products.indexOf(item)
      confirm(`Sei sicuro di voler rimuovere il prodotto ${item.id} ${item.title}?`) &&
        this.quotation.products.splice(index, 1)
    },
    addProduct (item) {
      // TODO: need to chekc multiple instances of same SKU?
      this.quotation.products.push(item)
      this.productPicker = false
    },
    fetch () {
      // get id from url
      if (this.$route.params.id === null || this.$route.params.id === undefined) {
        return
      }

      axios({
        method: 'GET',
        url: `${this.$store.state.baseUrl}/api/quotation/${this.$route.params.id}`,
        headers: this.apiheaders,
      })
        .then((resp) => {
          if (resp.status === 200) {
            // tweak back data for shipping and payment
            const quotation = {}

            this.id = resp.data._id
            quotation.id = resp.data._id
            quotation.language = resp.data.language
            quotation.priceList = resp.data.priceList
            if (quotation.priceList === null || quotation.priceList === undefined || quotation.priceList === ''){
              quotation.priceList = 'Italiano'
            }
            quotation.name = resp.data.name
            quotation.company = resp.data.company
            quotation.draft = resp.data.draft
            quotation.note = resp.data.note
            quotation.production = resp.data.production
            quotation.products = resp.data.products

            quotation.payment = {}
            if (
              resp.data.payment === this.options.tbd ||
              (resp.data.payment === this.options.banktransfer) |
                (resp.data.payment === this.options.banktransfer30ggdf)
            ) {
              quotation.payment.method = resp.data.payment
              quotation.payment.note = ''
            } else {
              quotation.payment.method = this.options.note
              quotation.payment.note = resp.data.payment
            }

            quotation.shipping = {}
            if (resp.data.shipping === this.options.exworks || resp.data.shipping === this.options.included) {
              quotation.shipping.method = resp.data.shipping
              quotation.shipping.note = ''
            } else {
              quotation.shipping.method = this.options.note
              quotation.shipping.note = resp.data.shipping
            }

            quotation.minOrder = {}
            if (resp.data.minOrder === this.options.mixed || resp.data.minOrder === this.options.reference) {
              quotation.minOrder.method = resp.data.minOrder
              quotation.minOrder.note = ''
            } else {
              quotation.minOrder.method = this.options.note
              quotation.minOrder.note = resp.data.minOrder
            }

            quotation.lead = {}
            if (resp.data.lead === this.options.fivetoten) {
              quotation.lead.method = resp.data.lead
              quotation.lead.note = ''
            } else if (resp.data.lead === this.options.tentofiftheen) {
              quotation.lead.method = resp.data.lead
              quotation.lead.note = ''
            } else {
              quotation.lead.method = this.options.note
              quotation.lead.note = resp.data.lead
            }

            this.quotation = quotation
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          alert(err)
        })
    },
    submit (draft, step) {
      this.step = step
      this.quotation.draft = draft

      const tmp = _.cloneDeep(this.quotation)

      // fix shipping
      if (
        this.quotation.shipping.method === this.options.exworks ||
        this.quotation.shipping.method === this.options.included
      ) {
        tmp.shipping = this.quotation.shipping.method
      } else {
        tmp.shipping = this.quotation.shipping.note
      }
      // fix payment
      if (
        this.quotation.payment.method === this.options.tbd ||
        this.quotation.payment.method === this.options.banktransfer ||
        this.quotation.payment.method === this.options.banktransfer30ggdf
      ) {
        tmp.payment = this.quotation.payment.method
      } else {
        tmp.payment = this.quotation.payment.note
      }

      // fix minOrder
      if (
        this.quotation.minOrder.method === this.options.mixed ||
        this.quotation.minOrder.method === this.options.reference
      ) {
        tmp.minOrder = this.quotation.minOrder.method
      } else {
        tmp.minOrder = this.quotation.minOrder.note
      }

      // fix production
      if (
        this.quotation.minOrder.method === this.options.mixed ||
        this.quotation.minOrder.method === this.options.reference
      ) {
        tmp.minOrder = this.quotation.minOrder.method
      } else {
        tmp.minOrder = this.quotation.minOrder.note
      }

      // fix lead
      if (this.quotation.lead.method === this.options.fivetoten) {
        tmp.lead = this.quotation.lead.method
      } else if (this.quotation.lead.method === this.options.tentofiftheen) {
        tmp.lead = this.quotation.lead.method
      } else {
        tmp.lead = this.quotation.lead.note
      }

      if (this.id != null) {
        axios({
          method: 'PUT',
          url: `${this.$store.state.baseUrl}/api/quotation/${this.id}`,
          headers: this.apiheaders,
          data: { quotation: tmp },
        })
          .then((resp) => {
            if (resp.status === 200) {
              this.snack = true
              this.snackColor = 'success'
              this.snackText = 'Aggiornato correttamente'

              if (this.step === 10) {
                this.snackText = 'Salvato correttamente'
                this.$router.push(`/list`)
              }
            } else {
              alert("Si e' verificato un errore in fase di salvataggio")
            }
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            alert(err)
          })
      } else {
        axios({
          method: 'POST',
          url: `${this.$store.state.baseUrl}/api/quotation`,
          headers: this.apiheaders,
          data: { quotation: tmp },
        })
          .then((resp) => {
            this.id = resp.data.id

            this.snack = true
            this.snackColor = 'success'
            this.snackText = 'Salvato correttamente'
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            alert(err)
          })
      }
    },
  },
}
</script>
